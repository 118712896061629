.Wrapper {
  position: relative;  
}

.Article {
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
  color: #3A3A3A;
}

.Article :global(p, span) {
  color: #3A3A3A;
}

@media (max-width: 1200px) {
  .Wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.Article :global(.lst-kix_1qm9xl99o2ea-0>li) {
  counter-increment: lst-ctn-kix_1qm9xl99o2ea-0
}

.Article :global(.lst-kix_ex9q44y4mwsm-6>li) {
  counter-increment: lst-ctn-kix_ex9q44y4mwsm-6
}

.Article :global(ol.lst-kix_na8qjobia8mt-0) {
  list-style-type: none
}

.Article :global(.lst-kix_na8qjobia8mt-0>li) {
  counter-increment: lst-ctn-kix_na8qjobia8mt-0
}

.Article :global(.lst-kix_jw3v0hee1dhx-2>li) {
  counter-increment: lst-ctn-kix_jw3v0hee1dhx-2
}

.Article :global(ol.lst-kix_na8qjobia8mt-5) {
  list-style-type: none
}

.Article :global(ol.lst-kix_na8qjobia8mt-6) {
  list-style-type: none
}

.Article :global(ol.lst-kix_na8qjobia8mt-7) {
  list-style-type: none
}

.Article :global(ol.lst-kix_na8qjobia8mt-8) {
  list-style-type: none
}

.Article :global(ol.lst-kix_na8qjobia8mt-1) {
  list-style-type: none
}

.Article :global(.lst-kix_y098w24ys2zf-8>li) {
  counter-increment: lst-ctn-kix_y098w24ys2zf-8
}

.Article :global(ol.lst-kix_na8qjobia8mt-2) {
  list-style-type: none
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-5.start) {
  counter-reset: lst-ctn-kix_1qm9xl99o2ea-5 0
}

.Article :global(ol.lst-kix_na8qjobia8mt-3) {
  list-style-type: none
}

.Article :global(ol.lst-kix_na8qjobia8mt-4) {
  list-style-type: none
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-2.start) {
  counter-reset: lst-ctn-kix_npwjo5n0m6t8-2 0
}

.Article :global(.lst-kix_npwjo5n0m6t8-4>li) {
  counter-increment: lst-ctn-kix_npwjo5n0m6t8-4
}

.Article :global(.lst-kix_npwjo5n0m6t8-4>li:before) {
  content: ""counter(lst-ctn-kix_npwjo5n0m6t8-4, lower-latin) ". "
}

.Article :global(ol.lst-kix_y098w24ys2zf-3.start) {
  counter-reset: lst-ctn-kix_y098w24ys2zf-3 0
}

.Article :global(.lst-kix_jw3v0hee1dhx-4>li) {
  counter-increment: lst-ctn-kix_jw3v0hee1dhx-4
}

.Article :global(.lst-kix_npwjo5n0m6t8-3>li:before) {
  content: ""counter(lst-ctn-kix_npwjo5n0m6t8-3, decimal) ". "
}

.Article :global(.lst-kix_npwjo5n0m6t8-1>li:before) {
  content: ""counter(lst-ctn-kix_npwjo5n0m6t8-1, lower-latin) ". "
}

.Article :global(.lst-kix_y098w24ys2zf-6>li) {
  counter-increment: lst-ctn-kix_y098w24ys2zf-6
}

.Article :global(ol.lst-kix_na8qjobia8mt-7.start) {
  counter-reset: lst-ctn-kix_na8qjobia8mt-7 0
}

.Article :global(.lst-kix_npwjo5n0m6t8-0>li:before) {
  content: ""counter(lst-ctn-kix_npwjo5n0m6t8-0, decimal) ". "
}

.Article :global(.lst-kix_npwjo5n0m6t8-2>li:before) {
  content: ""counter(lst-ctn-kix_npwjo5n0m6t8-2, lower-roman) ". "
}

.Article :global(.lst-kix_ex9q44y4mwsm-4>li) {
  counter-increment: lst-ctn-kix_ex9q44y4mwsm-4
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-4.start) {
  counter-reset: lst-ctn-kix_jw3v0hee1dhx-4 0
}

.Article :global(ol.lst-kix_na8qjobia8mt-1.start) {
  counter-reset: lst-ctn-kix_na8qjobia8mt-1 0
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-8.start) {
  counter-reset: lst-ctn-kix_npwjo5n0m6t8-8 0
}

.Article :global(.lst-kix_npwjo5n0m6t8-2>li) {
  counter-increment: lst-ctn-kix_npwjo5n0m6t8-2
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-0.start) {
  counter-reset: lst-ctn-kix_1qm9xl99o2ea-0 0
}

.Article :global(.lst-kix_npwjo5n0m6t8-8>li) {
  counter-increment: lst-ctn-kix_npwjo5n0m6t8-8
}

.Article :global(.lst-kix_ex9q44y4mwsm-2>li) {
  counter-increment: lst-ctn-kix_ex9q44y4mwsm-2
}

.Article :global(.lst-kix_npwjo5n0m6t8-5>li:before) {
  content: ""counter(lst-ctn-kix_npwjo5n0m6t8-5, lower-roman) ". "
}

.Article :global(ol.lst-kix_na8qjobia8mt-2.start) {
  counter-reset: lst-ctn-kix_na8qjobia8mt-2 0
}

.Article :global(.lst-kix_na8qjobia8mt-4>li) {
  counter-increment: lst-ctn-kix_na8qjobia8mt-4
}

.Article :global(.lst-kix_npwjo5n0m6t8-6>li:before) {
  content: ""counter(lst-ctn-kix_npwjo5n0m6t8-6, decimal) ". "
}

.Article :global(.lst-kix_na8qjobia8mt-7>li) {
  counter-increment: lst-ctn-kix_na8qjobia8mt-7
}

.Article :global(.lst-kix_npwjo5n0m6t8-7>li:before) {
  content: ""counter(lst-ctn-kix_npwjo5n0m6t8-7, lower-latin) ". "
}

.Article :global(.lst-kix_ex9q44y4mwsm-8>li) {
  counter-increment: lst-ctn-kix_ex9q44y4mwsm-8
}

.Article :global(.lst-kix_npwjo5n0m6t8-8>li:before) {
  content: ""counter(lst-ctn-kix_npwjo5n0m6t8-8, lower-roman) ". "
}

.Article :global(li.li-bullet-2:before) {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-6.start) {
  counter-reset: lst-ctn-kix_1qm9xl99o2ea-6 0
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-3.start) {
  counter-reset: lst-ctn-kix_npwjo5n0m6t8-3 0
}

.Article :global(.lst-kix_1qm9xl99o2ea-2>li) {
  counter-increment: lst-ctn-kix_1qm9xl99o2ea-2
}

.Article :global(.lst-kix_y098w24ys2zf-1>li) {
  counter-increment: lst-ctn-kix_y098w24ys2zf-1
}

.Article :global(.lst-kix_y098w24ys2zf-2>li) {
  counter-increment: lst-ctn-kix_y098w24ys2zf-2
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-0) {
  list-style-type: none
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-2) {
  list-style-type: none
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-1) {
  list-style-type: none
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-4) {
  list-style-type: none
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-3) {
  list-style-type: none
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-6) {
  list-style-type: none
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-2.start) {
  counter-reset: lst-ctn-kix_ex9q44y4mwsm-2 0
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-5) {
  list-style-type: none
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-8) {
  list-style-type: none
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-7) {
  list-style-type: none
}

.Article :global(ol.lst-kix_y098w24ys2zf-7.start) {
  counter-reset: lst-ctn-kix_y098w24ys2zf-7 0
}

.Article :global(ol.lst-kix_y098w24ys2zf-6) {
  list-style-type: none
}

.Article :global(ol.lst-kix_y098w24ys2zf-7) {
  list-style-type: none
}

.Article :global(ol.lst-kix_y098w24ys2zf-8) {
  list-style-type: none
}

.Article :global(.lst-kix_y098w24ys2zf-0>li) {
  counter-increment: lst-ctn-kix_y098w24ys2zf-0
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-1.start) {
  counter-reset: lst-ctn-kix_1qm9xl99o2ea-1 0
}

.Article :global(.lst-kix_1qm9xl99o2ea-3>li:before) {
  content: ""counter(lst-ctn-kix_1qm9xl99o2ea-3, decimal) ". "
}

.Article :global(.lst-kix_ex9q44y4mwsm-1>li:before) {
  content: ""counter(lst-ctn-kix_ex9q44y4mwsm-1, lower-latin) ". "
}

.Article :global(li.li-bullet-1:before) {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt
}

.Article :global(.lst-kix_jw3v0hee1dhx-7>li) {
  counter-increment: lst-ctn-kix_jw3v0hee1dhx-7
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-5.start) {
  counter-reset: lst-ctn-kix_ex9q44y4mwsm-5 0
}

.Article :global(.lst-kix_1qm9xl99o2ea-5>li:before) {
  content: ""counter(lst-ctn-kix_1qm9xl99o2ea-5, lower-roman) ". "
}

.Article :global(.lst-kix_na8qjobia8mt-8>li) {
  counter-increment: lst-ctn-kix_na8qjobia8mt-8
}

.Article :global(.lst-kix_1qm9xl99o2ea-1>li:before) {
  content: ""counter(lst-ctn-kix_1qm9xl99o2ea-1, lower-latin) ". "
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-1.start) {
  counter-reset: lst-ctn-kix_npwjo5n0m6t8-1 0
}

.Article :global(.lst-kix_na8qjobia8mt-3>li) {
  counter-increment: lst-ctn-kix_na8qjobia8mt-3
}

.Article :global(.lst-kix_ex9q44y4mwsm-3>li) {
  counter-increment: lst-ctn-kix_ex9q44y4mwsm-3
}

.Article :global(.lst-kix_1qm9xl99o2ea-3>li) {
  counter-increment: lst-ctn-kix_1qm9xl99o2ea-3
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-4.start) {
  counter-reset: lst-ctn-kix_1qm9xl99o2ea-4 0
}

.Article :global(.lst-kix_jw3v0hee1dhx-4>li:before) {
  content: ""counter(lst-ctn-kix_jw3v0hee1dhx-4, lower-latin) ". "
}

.Article :global(.lst-kix_na8qjobia8mt-2>li) {
  counter-increment: lst-ctn-kix_na8qjobia8mt-2
}

.Article :global(ol.lst-kix_na8qjobia8mt-0.start) {
  counter-reset: lst-ctn-kix_na8qjobia8mt-0 0
}

.Article :global(.lst-kix_1qm9xl99o2ea-7>li:before) {
  content: ""counter(lst-ctn-kix_1qm9xl99o2ea-7, lower-latin) ". "
}

.Article :global(.lst-kix_jw3v0hee1dhx-2>li:before) {
  content: ""counter(lst-ctn-kix_jw3v0hee1dhx-2, lower-roman) ". "
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-4.start) {
  counter-reset: lst-ctn-kix_ex9q44y4mwsm-4 0
}

.Article :global(.lst-kix_ex9q44y4mwsm-5>li:before) {
  content: ""counter(lst-ctn-kix_ex9q44y4mwsm-5, lower-roman) ". "
}

.Article :global(.lst-kix_1qm9xl99o2ea-4>li) {
  counter-increment: lst-ctn-kix_1qm9xl99o2ea-4
}

.Article :global(.lst-kix_jw3v0hee1dhx-0>li:before) {
  content: ""counter(lst-ctn-kix_jw3v0hee1dhx-0, decimal) ". "
}

.Article :global(.lst-kix_ex9q44y4mwsm-3>li:before) {
  content: ""counter(lst-ctn-kix_ex9q44y4mwsm-3, decimal) ". "
}

.Article :global(.lst-kix_na8qjobia8mt-0>li:before) {
  content: ""counter(lst-ctn-kix_na8qjobia8mt-0, decimal) ". "
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-8.start) {
  counter-reset: lst-ctn-kix_jw3v0hee1dhx-8 0
}

.Article :global(ol.lst-kix_y098w24ys2zf-8.start) {
  counter-reset: lst-ctn-kix_y098w24ys2zf-8 0
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-0.start) {
  counter-reset: lst-ctn-kix_npwjo5n0m6t8-0 0
}

.Article :global(.lst-kix_na8qjobia8mt-2>li:before) {
  content: ""counter(lst-ctn-kix_na8qjobia8mt-2, lower-roman) ". "
}

.Article :global(.lst-kix_npwjo5n0m6t8-6>li) {
  counter-increment: lst-ctn-kix_npwjo5n0m6t8-6
}

.Article :global(.lst-kix_npwjo5n0m6t8-0>li) {
  counter-increment: lst-ctn-kix_npwjo5n0m6t8-0
}

.Article :global(.lst-kix_ex9q44y4mwsm-7>li:before) {
  content: ""counter(lst-ctn-kix_ex9q44y4mwsm-7, lower-latin) ". "
}

.Article :global(.lst-kix_na8qjobia8mt-8>li:before) {
  content: ""counter(lst-ctn-kix_na8qjobia8mt-8, lower-roman) ". "
}

.Article :global(.lst-kix_na8qjobia8mt-6>li:before) {
  content: ""counter(lst-ctn-kix_na8qjobia8mt-6, decimal) ". "
}

.Article :global(.lst-kix_jw3v0hee1dhx-0>li) {
  counter-increment: lst-ctn-kix_jw3v0hee1dhx-0
}

.Article :global(.lst-kix_jw3v0hee1dhx-6>li:before) {
  content: ""counter(lst-ctn-kix_jw3v0hee1dhx-6, decimal) ". "
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-3.start) {
  counter-reset: lst-ctn-kix_1qm9xl99o2ea-3 0
}

.Article :global(.lst-kix_na8qjobia8mt-4>li:before) {
  content: ""counter(lst-ctn-kix_na8qjobia8mt-4, lower-latin) ". "
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-3.start) {
  counter-reset: lst-ctn-kix_ex9q44y4mwsm-3 0
}

.Article :global(.lst-kix_jw3v0hee1dhx-6>li) {
  counter-increment: lst-ctn-kix_jw3v0hee1dhx-6
}

.Article :global(.lst-kix_y098w24ys2zf-7>li) {
  counter-increment: lst-ctn-kix_y098w24ys2zf-7
}

.Article :global(.lst-kix_jw3v0hee1dhx-8>li:before) {
  content: ""counter(lst-ctn-kix_jw3v0hee1dhx-8, lower-roman) ". "
}

.Article :global(.lst-kix_npwjo5n0m6t8-5>li) {
  counter-increment: lst-ctn-kix_npwjo5n0m6t8-5
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-6.start) {
  counter-reset: lst-ctn-kix_ex9q44y4mwsm-6 0
}

.Article :global(ol.lst-kix_y098w24ys2zf-0.start) {
  counter-reset: lst-ctn-kix_y098w24ys2zf-0 5
}

.Article :global(.lst-kix_1qm9xl99o2ea-1>li) {
  counter-increment: lst-ctn-kix_1qm9xl99o2ea-1
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-2.start) {
  counter-reset: lst-ctn-kix_1qm9xl99o2ea-2 0
}

.Article :global(.lst-kix_jw3v0hee1dhx-1>li) {
  counter-increment: lst-ctn-kix_jw3v0hee1dhx-1
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-8.start) {
  counter-reset: lst-ctn-kix_1qm9xl99o2ea-8 0
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-5.start) {
  counter-reset: lst-ctn-kix_npwjo5n0m6t8-5 0
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-1.start) {
  counter-reset: lst-ctn-kix_jw3v0hee1dhx-1 0
}

.Article :global(.lst-kix_ex9q44y4mwsm-7>li) {
  counter-increment: lst-ctn-kix_ex9q44y4mwsm-7
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-4) {
  list-style-type: none
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-5) {
  list-style-type: none
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-6) {
  list-style-type: none
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-7) {
  list-style-type: none
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-8) {
  list-style-type: none
}

.Article :global(ol.lst-kix_na8qjobia8mt-4.start) {
  counter-reset: lst-ctn-kix_na8qjobia8mt-4 0
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-3) {
  list-style-type: none
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-2) {
  list-style-type: none
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-1) {
  list-style-type: none
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-0) {
  list-style-type: none
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-7) {
  list-style-type: none
}

.Article :global(ol.lst-kix_y098w24ys2zf-6.start) {
  counter-reset: lst-ctn-kix_y098w24ys2zf-6 0
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-0) {
  list-style-type: none
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-6) {
  list-style-type: none
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-1) {
  list-style-type: none
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-5) {
  list-style-type: none
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-2) {
  list-style-type: none
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-4) {
  list-style-type: none
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-3) {
  list-style-type: none
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-1.start) {
  counter-reset: lst-ctn-kix_ex9q44y4mwsm-1 0
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-7.start) {
  counter-reset: lst-ctn-kix_1qm9xl99o2ea-7 0
}

.Article :global(.lst-kix_y098w24ys2zf-1>li:before) {
  content: ""counter(lst-ctn-kix_y098w24ys2zf-1, lower-latin) ". "
}

.Article :global(.lst-kix_y098w24ys2zf-3>li:before) {
  content: ""counter(lst-ctn-kix_y098w24ys2zf-3, decimal) ". "
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-7.start) {
  counter-reset: lst-ctn-kix_jw3v0hee1dhx-7 0
}

.Article :global(.lst-kix_y098w24ys2zf-2>li:before) {
  content: ""counter(lst-ctn-kix_y098w24ys2zf-2, lower-roman) ". "
}

.Article :global(.lst-kix_jw3v0hee1dhx-5>li) {
  counter-increment: lst-ctn-kix_jw3v0hee1dhx-5
}

.Article :global(.lst-kix_y098w24ys2zf-5>li:before) {
  content: ""counter(lst-ctn-kix_y098w24ys2zf-5, lower-roman) ". "
}

.Article :global(ol.lst-kix_na8qjobia8mt-3.start) {
  counter-reset: lst-ctn-kix_na8qjobia8mt-3 0
}

.Article :global(.lst-kix_y098w24ys2zf-4>li:before) {
  content: ""counter(lst-ctn-kix_y098w24ys2zf-4, lower-latin) ". "
}

.Article :global(ol.lst-kix_y098w24ys2zf-5.start) {
  counter-reset: lst-ctn-kix_y098w24ys2zf-5 0
}

.Article :global(.lst-kix_npwjo5n0m6t8-1>li) {
  counter-increment: lst-ctn-kix_npwjo5n0m6t8-1
}

.Article :global(.lst-kix_y098w24ys2zf-5>li) {
  counter-increment: lst-ctn-kix_y098w24ys2zf-5
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-4.start) {
  counter-reset: lst-ctn-kix_npwjo5n0m6t8-4 0
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-0.start) {
  counter-reset: lst-ctn-kix_jw3v0hee1dhx-0 6
}

.Article :global(ol.lst-kix_y098w24ys2zf-0) {
  list-style-type: none
}

.Article :global(ol.lst-kix_y098w24ys2zf-1) {
  list-style-type: none
}

.Article :global(ol.lst-kix_y098w24ys2zf-2) {
  list-style-type: none
}

.Article :global(.lst-kix_npwjo5n0m6t8-7>li) {
  counter-increment: lst-ctn-kix_npwjo5n0m6t8-7
}

.Article :global(ol.lst-kix_y098w24ys2zf-3) {
  list-style-type: none
}

.Article :global(ol.lst-kix_y098w24ys2zf-4) {
  list-style-type: none
}

.Article :global(.lst-kix_y098w24ys2zf-0>li:before) {
  content: ""counter(lst-ctn-kix_y098w24ys2zf-0, decimal) ". "
}

.Article :global(ol.lst-kix_y098w24ys2zf-5) {
  list-style-type: none
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-7.start) {
  counter-reset: lst-ctn-kix_ex9q44y4mwsm-7 0
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-0.start) {
  counter-reset: lst-ctn-kix_ex9q44y4mwsm-0 4
}

.Article :global(.lst-kix_ex9q44y4mwsm-5>li) {
  counter-increment: lst-ctn-kix_ex9q44y4mwsm-5
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-6.start) {
  counter-reset: lst-ctn-kix_jw3v0hee1dhx-6 0
}

.Article :global(.lst-kix_1qm9xl99o2ea-8>li) {
  counter-increment: lst-ctn-kix_1qm9xl99o2ea-8
}

.Article :global(.lst-kix_1qm9xl99o2ea-5>li) {
  counter-increment: lst-ctn-kix_1qm9xl99o2ea-5
}

.Article :global(.lst-kix_y098w24ys2zf-6>li:before) {
  content: ""counter(lst-ctn-kix_y098w24ys2zf-6, decimal) ". "
}

.Article :global(.lst-kix_y098w24ys2zf-7>li:before) {
  content: ""counter(lst-ctn-kix_y098w24ys2zf-7, lower-latin) ". "
}

.Article :global(.lst-kix_na8qjobia8mt-1>li) {
  counter-increment: lst-ctn-kix_na8qjobia8mt-1
}

.Article :global(.lst-kix_y098w24ys2zf-8>li:before) {
  content: ""counter(lst-ctn-kix_y098w24ys2zf-8, lower-roman) ". "
}

.Article :global(ol.lst-kix_na8qjobia8mt-8.start) {
  counter-reset: lst-ctn-kix_na8qjobia8mt-8 0
}

.Article :global(.lst-kix_1qm9xl99o2ea-6>li) {
  counter-increment: lst-ctn-kix_1qm9xl99o2ea-6
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-6.start) {
  counter-reset: lst-ctn-kix_npwjo5n0m6t8-6 0
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-2.start) {
  counter-reset: lst-ctn-kix_jw3v0hee1dhx-2 0
}

.Article :global(.lst-kix_ex9q44y4mwsm-0>li) {
  counter-increment: lst-ctn-kix_ex9q44y4mwsm-0
}

.Article :global(ol.lst-kix_y098w24ys2zf-4.start) {
  counter-reset: lst-ctn-kix_y098w24ys2zf-4 0
}

.Article :global(.lst-kix_na8qjobia8mt-5>li) {
  counter-increment: lst-ctn-kix_na8qjobia8mt-5
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-5.start) {
  counter-reset: lst-ctn-kix_jw3v0hee1dhx-5 0
}

.Article :global(.lst-kix_na8qjobia8mt-6>li) {
  counter-increment: lst-ctn-kix_na8qjobia8mt-6
}

.Article :global(.lst-kix_jw3v0hee1dhx-8>li) {
  counter-increment: lst-ctn-kix_jw3v0hee1dhx-8
}

.Article :global(ol.lst-kix_na8qjobia8mt-5.start) {
  counter-reset: lst-ctn-kix_na8qjobia8mt-5 0
}

.Article :global(.lst-kix_1qm9xl99o2ea-7>li) {
  counter-increment: lst-ctn-kix_1qm9xl99o2ea-7
}

.Article :global(.lst-kix_1qm9xl99o2ea-2>li:before) {
  content: ""counter(lst-ctn-kix_1qm9xl99o2ea-2, lower-roman) ". "
}

.Article :global(.lst-kix_ex9q44y4mwsm-0>li:before) {
  content: ""counter(lst-ctn-kix_ex9q44y4mwsm-0, decimal) ". "
}

.Article :global(.lst-kix_ex9q44y4mwsm-2>li:before) {
  content: ""counter(lst-ctn-kix_ex9q44y4mwsm-2, lower-roman) ". "
}

.Article :global(.lst-kix_1qm9xl99o2ea-0>li:before) {
  content: ""counter(lst-ctn-kix_1qm9xl99o2ea-0, decimal) ". "
}

.Article :global(.lst-kix_1qm9xl99o2ea-4>li:before) {
  content: ""counter(lst-ctn-kix_1qm9xl99o2ea-4, lower-latin) ". "
}

.Article :global(.lst-kix_1qm9xl99o2ea-6>li:before) {
  content: ""counter(lst-ctn-kix_1qm9xl99o2ea-6, decimal) ". "
}

.Article :global(.lst-kix_y098w24ys2zf-3>li) {
  counter-increment: lst-ctn-kix_y098w24ys2zf-3
}

.Article :global(.lst-kix_ex9q44y4mwsm-1>li) {
  counter-increment: lst-ctn-kix_ex9q44y4mwsm-1
}

.Article :global(ol.lst-kix_ex9q44y4mwsm-8.start) {
  counter-reset: lst-ctn-kix_ex9q44y4mwsm-8 0
}

.Article :global(ol.lst-kix_1qm9xl99o2ea-8) {
  list-style-type: none
}

.Article :global(.lst-kix_y098w24ys2zf-4>li) {
  counter-increment: lst-ctn-kix_y098w24ys2zf-4
}

.Article :global(.lst-kix_jw3v0hee1dhx-3>li:before) {
  content: ""counter(lst-ctn-kix_jw3v0hee1dhx-3, decimal) ". "
}

.Article :global(.lst-kix_1qm9xl99o2ea-8>li:before) {
  content: ""counter(lst-ctn-kix_1qm9xl99o2ea-8, lower-roman) ". "
}

.Article :global(.lst-kix_jw3v0hee1dhx-1>li:before) {
  content: ""counter(lst-ctn-kix_jw3v0hee1dhx-1, lower-latin) ". "
}

.Article :global(ol.lst-kix_jw3v0hee1dhx-3.start) {
  counter-reset: lst-ctn-kix_jw3v0hee1dhx-3 0
}

.Article :global(.lst-kix_ex9q44y4mwsm-4>li:before) {
  content: ""counter(lst-ctn-kix_ex9q44y4mwsm-4, lower-latin) ". "
}

.Article :global(ol.lst-kix_y098w24ys2zf-2.start) {
  counter-reset: lst-ctn-kix_y098w24ys2zf-2 0
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-0) {
  list-style-type: none
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-7.start) {
  counter-reset: lst-ctn-kix_npwjo5n0m6t8-7 0
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-3) {
  list-style-type: none
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-4) {
  list-style-type: none
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-1) {
  list-style-type: none
}

.Article :global(ol.lst-kix_y098w24ys2zf-1.start) {
  counter-reset: lst-ctn-kix_y098w24ys2zf-1 0
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-2) {
  list-style-type: none
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-7) {
  list-style-type: none
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-8) {
  list-style-type: none
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-5) {
  list-style-type: none
}

.Article :global(.lst-kix_ex9q44y4mwsm-6>li:before) {
  content: ""counter(lst-ctn-kix_ex9q44y4mwsm-6, decimal) ". "
}

.Article :global(ol.lst-kix_npwjo5n0m6t8-6) {
  list-style-type: none
}

.Article :global(.lst-kix_na8qjobia8mt-1>li:before) {
  content: ""counter(lst-ctn-kix_na8qjobia8mt-1, lower-latin) ". "
}

.Article :global(.lst-kix_npwjo5n0m6t8-3>li) {
  counter-increment: lst-ctn-kix_npwjo5n0m6t8-3
}

.Article :global(li.li-bullet-0:before) {
  margin-left: -21.3pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 21.3pt
}

.Article :global(.lst-kix_na8qjobia8mt-7>li:before) {
  content: ""counter(lst-ctn-kix_na8qjobia8mt-7, lower-latin) ". "
}

.Article :global(.lst-kix_ex9q44y4mwsm-8>li:before) {
  content: ""counter(lst-ctn-kix_ex9q44y4mwsm-8, lower-roman) ". "
}

.Article :global(.lst-kix_jw3v0hee1dhx-5>li:before) {
  content: ""counter(lst-ctn-kix_jw3v0hee1dhx-5, lower-roman) ". "
}

.Article :global(ol.lst-kix_na8qjobia8mt-6.start) {
  counter-reset: lst-ctn-kix_na8qjobia8mt-6 0
}

.Article :global(.lst-kix_jw3v0hee1dhx-7>li:before) {
  content: ""counter(lst-ctn-kix_jw3v0hee1dhx-7, lower-latin) ". "
}

.Article :global(.lst-kix_na8qjobia8mt-3>li:before) {
  content: ""counter(lst-ctn-kix_na8qjobia8mt-3, decimal) ". "
}

.Article :global(.lst-kix_jw3v0hee1dhx-3>li) {
  counter-increment: lst-ctn-kix_jw3v0hee1dhx-3
}

.Article :global(.lst-kix_na8qjobia8mt-5>li:before) {
  content: ""counter(lst-ctn-kix_na8qjobia8mt-5, lower-roman) ". "
}

.Article :global(ol) {
  margin: 0;
  padding: 0
}

.Article :global(table td,
table th) {
  padding: 0
}

.Article :global(.c30) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 3pt
}

.Article :global(.c66) {
  margin-left: 136pt;
  padding-top: 0pt;
  text-indent: -33pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 7pt
}

.Article :global(.c27) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.4000000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 8pt
}

.Article :global(.c57) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 12pt
}

.Article :global(.c15) {
  margin-left: 136pt;
  padding-top: 0pt;
  text-indent: -33pt;
  padding-bottom: 0pt;
  line-height: 1.32;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 34pt
}

.Article :global(.c50) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.4000000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 1pt
}

.Article :global(.c74) {
  margin-left: 136pt;
  padding-top: 0pt;
  text-indent: -33pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 7pt
}

.Article :global(.c145) {
  margin-left: 29pt;
  padding-top: 0pt;
  text-indent: -1pt;
  padding-bottom: 0pt;
  line-height: 1.29;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 11pt
}

.Article :global(.c64) {
  margin-left: 136pt;
  padding-top: 0pt;
  text-indent: -33pt;
  padding-bottom: 0pt;
  line-height: 1.32;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 9pt
}

.Article :global(.c28) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 14pt
}

.Article :global(.c96) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 5pt
}

.Article :global(.c5) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.4000000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 7pt
}

.Article :global(.c65) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 45pt
}

.Article :global(.c16) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 19pt
}

.Article :global(.c151) {
  margin-left: 53.4pt;
  padding-top: 0pt;
  padding-left: 3.3pt;
  padding-bottom: 0pt;
  line-height: 1.32;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 1pt
}

.Article :global(.c58) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 3pt
}

.Article :global(.c22) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.32;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 1pt
}

.Article :global(.c12) {
  margin-left: 169pt;
  padding-top: 0pt;
  text-indent: -33pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 8pt
}

.Article :global(.c72) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.29;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 4pt
}

.Article :global(.c44) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.49;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 6pt
}

.Article :global(.c7) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.29;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 7pt
}

.Article :global(.c24) {
  margin-left: 136pt;
  padding-top: 0pt;
  text-indent: -33pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 17pt
}

.Article :global(.c104) {
  margin-left: 169pt;
  padding-top: 0pt;
  text-indent: -33pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 5pt
}

.Article :global(.c71) {
  margin-left: 136pt;
  padding-top: 0pt;
  text-indent: -33pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 11pt
}

.Article :global(.c43) {
  margin-left: 136pt;
  padding-top: 12pt;
  text-indent: -33pt;
  padding-bottom: 12pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c70) {
  margin-left: 30pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.29;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 1pt
}

.Article :global(.c81) {
  margin-left: 110pt;
  padding-top: 12pt;
  text-indent: -40pt;
  padding-bottom: 12pt;
  line-height: 0.9454545454545454;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c19) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.32;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 1pt;
  height: 11pt
}

.Article :global(.c23) {
  margin-left: 70pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.4000000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 7pt
}

.Article :global(.c78) {
  margin-left: 36pt;
  padding-top: 12pt;
  padding-left: 0pt;
  padding-bottom: 12pt;
  line-height: 0.9454545454545454;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c1) {
  margin-left: 136pt;
  padding-top: 12pt;
  text-indent: -33pt;
  padding-bottom: 12pt;
  line-height: 0.9454545454545454;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c83) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 13pt;
  height: 11pt
}

.Article :global(.c31) {
  margin-left: 110pt;
  padding-top: 12pt;
  text-indent: -40pt;
  padding-bottom: 12pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c63) {
  margin-left: 30pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.55;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 16pt
}

.Article :global(.c90) {
  margin-left: 30pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.37;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 7pt
}

.Article :global(.c67) {
  margin-left: 30pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 2pt
}

.Article :global(.c17) {
  margin-left: 136pt;
  padding-top: 12pt;
  text-indent: -33pt;
  padding-bottom: 12pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: justify
}

.Article :global(.c68) {
  margin-left: 30pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 2pt
}

.Article :global(.c53) {
  margin-left: 60pt;
  padding-top: 12pt;
  text-indent: -30pt;
  padding-bottom: 12pt;
  line-height: 0.9454545454545454;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c82) {
  margin-left: 110pt;
  padding-top: 12pt;
  text-indent: -40pt;
  padding-bottom: 12pt;
  line-height: 1.28;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c14) {
  margin-left: 70pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: justify;
  margin-right: 14pt
}

.Article :global(.c84) {
  margin-left: 110pt;
  padding-top: 12pt;
  text-indent: -40pt;
  padding-bottom: 12pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: justify
}

.Article :global(.c95) {
  margin-left: 30pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.55;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 32pt
}

.Article :global(.c103) {
  margin-left: 60pt;
  padding-top: 12pt;
  text-indent: -38.7pt;
  padding-bottom: 12pt;
  line-height: 0.9454545454545454;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c85) {
  margin-left: 169pt;
  padding-top: 12pt;
  text-indent: -33pt;
  padding-bottom: 12pt;
  line-height: 0.9454545454545454;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c92) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 1pt
}

.Article :global(.c150) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.32;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 7pt
}

.Article :global(.c136) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 9pt
}

.Article :global(.c49) {
  /* color: #000000; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  /* font-family: "Arial"; */
  font-style: normal
}

.Article :global(.c129) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 4pt
}

.Article :global(.c105) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.32;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 11pt
}

.Article :global(.c2) {
  /* color: #000000; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  /* font-family: "Arial"; */
  font-style: normal
}

.Article :global(.c127) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.32;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 13pt
}

.Article :global(.c111) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 21pt
}

.Article :global(.c110) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.49;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 22pt
}

.Article :global(.c146) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 1pt
}

.Article :global(.c94) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 13pt
}

.Article :global(.c140) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 17pt
}

.Article :global(.c108) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 9pt
}

.Article :global(.c143) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 3pt
}

.Article :global(.c130) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 8pt
}

.Article :global(.c147) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.29;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 11pt
}

.Article :global(.c91) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 27pt
}

.Article :global(.c8) {
  /* color: #000000; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10.5pt;
  /* font-family: "Arial"; */
  font-style: normal
}

.Article :global(.c62) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9454545454545454;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt
}

.Article :global(.c4) {
  /* color: #000000; */
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  /* font-family: "Arial"; */
  font-style: normal
}

.Article :global(.bold) {
  font-weight: 700;
}

.Article :global(.c109) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 5pt
}

.Article :global(.c47) {
  margin-left: 30pt;
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9454545454545454;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c122) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 2pt
}

.Article :global(.c25) {
  /* color: #000000; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  /* font-family: "Arial"; */
  font-style: normal
}

.Article :global(.c124) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 4pt
}

.Article :global(.c123) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 6pt
}

.Article :global(.c107) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.32;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 15pt
}

.Article :global(.c113) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 22pt
}

.Article :global(.c126) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.26;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 13pt
}

.Article :global(.c121) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.28;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 1pt
}

.Article :global(.c3) {
  /* color: #000000; */
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  /* font-family: "Arial"; */
  font-style: normal
}

.Article :global(.c46) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.2181818181818183;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c61) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.14545454545454548;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c112) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9454545454545454;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c75) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.018181818181818;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c26) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.3227272727272728;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c99) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.4909090909090907;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c106) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9136363636363637;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c135) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.48636363636363633;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c29) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.23636363636363636;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c141) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.30454545454545456;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c100) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.1181818181818182;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c56) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.4954545454545454;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c77) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.41818181818181815;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c34) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9409090909090909;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c36) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.4772727272727273;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c20) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.3772727272727273;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c10) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.4318181818181818;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c142) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9636363636363636;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c42) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.2227272727272727;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c35) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.32;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c32) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9590909090909091;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c116) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.33636363636363636;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c132) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9454545454545454;
  orphans: 2;
  widows: 2;
  text-align: center
}

.Article :global(.c149) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.2636363636363636;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c120) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.009090909090909092;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c148) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.3181818181818181;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c128) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.31;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c137) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.29;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c69) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9090909090909091;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c87) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.859090909090909;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c131) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.5863636363636364;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c76) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.25;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c41) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.4045454545454545;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c6) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.85;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c73) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.21363636363636365;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c38) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.5136363636363637;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c48) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.4681818181818182;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c125) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.309090909090909;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c21) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.0318181818181817;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c54) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.0681818181818181;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c102) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.2090909090909092;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c55) {
  /* color: #000000; */
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  /* font-family: "Arial"; */
  font-style: normal
}

.Article :global(.c52) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.0999999999999999;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c45) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.3409090909090909;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c59) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9454545454545454;
  orphans: 2;
  widows: 2;
  text-align: right
}

.Article :global(.c39) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.32727272727272727;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c139) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.268181818181818;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c86) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9772727272727273;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c89) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.6772727272727272;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c138) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.0136363636363637;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c11) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.1272727272727272;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c115) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c98) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.8727272727272727;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c79) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.38636363636363635;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c117) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.0636363636363635;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c119) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.8227272727272728;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c134) {
  /* color: #000000; */
  text-decoration: none;
  vertical-align: baseline;
  /* font-family: "Arial"; */
  font-style: normal
}

.Article :global(.c33) {
  background-color: #ffffff;
  max-width: 451.4pt;
  padding: 72pt 72pt 72pt 72pt
}

.Article :global(.c101) {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  text-decoration: underline
}

.Article :global(.c60) {
  padding: 0;
  margin: 0
}

.Article :global(.c97) {
  margin-left: 110pt;
  text-indent: -40pt
}

.Article :global(.c144) {
  margin-left: 169pt;
  text-indent: -33pt
}

.Article :global(.c80) {
  margin-left: 136pt;
  text-indent: -33pt
}

.Article :global(.c9) {
  color: inherit;
  text-decoration: inherit
}

.Article :global(.c118) {
  margin-left: 36pt;
  padding-left: 0pt
}

.Article :global(.c40) {
  font-size: 12pt
}

.Article :global(.c93) {
  vertical-align: super
}

.Article :global(.c13) {
  font-weight: 700
}

.Article :global(.c133) {
  margin-left: 30pt
}

.Article :global(.c37) {
  font-size: 11.5pt
}

.Article :global(.c51) {
  margin-left: 70pt
}

.Article :global(.c114) {
  height: 11pt
}

.Article :global(.c0) {
  font-size: 7pt
}

.Article :global(.c88) {
  font-style: italic
}

.Article :global(.c18) {
  font-size: 10.5pt
}

.Article :global(.title) {
  padding-top: 0pt;
  /* color: #000000; */
  font-size: 26pt;
  padding-bottom: 3pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.subtitle) {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(li) {
  /* color: #000000; */
  font-size: 11pt;
  /* font-family: "Arial"; */
}

.Article :global(p) {
  margin: 0;
  /* color: #000000; */
  font-size: 11pt;
  /* font-family: "Arial"; */
}

.Article :global(h1) {
  padding-top: 20pt;
  /* color: #000000; */
  font-size: 20pt;
  padding-bottom: 6pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(h2) {
  padding-top: 18pt;
  /* color: #000000; */
  font-size: 16pt;
  padding-bottom: 6pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(h3) {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(h4) {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(h5) {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(h6) {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left
}