.FooterContainer {
  width: 100%;
  height: 128px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  color: #fff;
  background: #010B29;
  text-align: center;
  /* position: absolute; */
  bottom: 0px;
  position: relative;
  overflow: hidden;
  margin-top: auto;
  padding-left: 38px;
  padding-right: 38px;
  box-sizing: border-box;
}

.CopyRight {
  color: #CFD3D7;
  font-family: 'Quicksand';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  z-index: 2;
}

.LeftLinks {
  color: #CFD3D7;
  text-align: center;
  font-family: 'Quicksand';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  gap: 30px;
}

@media (max-width: 768px) {
  .FooterContainer {
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }

  .LeftLinks {
    flex-direction: column;
    gap: 0px;
  }
}

@media (max-width: 1200px) {
  .FooterContainer {
    /* margin-bottom: 155px; */
  }
}