.PageLayoutContainer {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  overflow: hidden;

  min-height: 100vh; 
  display: flex;
  flex-direction: column;
}

.ContentContainer {
  display: flex;
  flex-direction: column;
  padding-top: 80px;
  height: 100%;
  flex-grow: 1;
}

@media (max-width: 1200px) {
  .ContentContainer {
    /* Space for navbar */
    padding-top: 80px;
  }
}