.Wrapper {
  position: relative;  
}

.Article {
  width: 100%;
  margin: 0 auto;
  max-width: 800px;
}

.Article :global(p) {
  color: #3A3A3A;
}

@media (max-width: 1200px) {
  .Wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.Article :global(ol) {
  margin: 0;
  padding: 0
}

.Article :global(table td,
table th) {
  padding: 0
}

.Article :global(.c48) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 15pt
}

.Article :global(.c45) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.29;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 3pt
}

.Article :global(.c51) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.4000000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 6pt
}

.Article :global(.c40) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.32;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 5pt
}

.Article :global(.c13) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.37;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 1pt
}

.Article :global(.c32) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 4pt
}

.Article :global(.c47) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 2pt
}

.Article :global(.c50) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 5pt
}

.Article :global(.c39) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 11pt
}

.Article :global(.c38) {
  margin-left: 110pt;
  padding-top: 0pt;
  text-indent: -40pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 16pt
}

.Article :global(.c42) {
  margin-left: 70pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.4000000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 10pt
}

.Article :global(.c33) {
  margin-left: 110pt;
  padding-top: 12pt;
  text-indent: -40pt;
  padding-bottom: 12pt;
  line-height: 1.4000000000000001;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c1) {
  margin-left: 60pt;
  padding-top: 12pt;
  text-indent: -30pt;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c19) {
  margin-left: 110pt;
  padding-top: 12pt;
  text-indent: -40pt;
  padding-bottom: 12pt;
  line-height: 1.29;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c26) {
  margin-left: 36pt;
  padding-top: 12pt;
  text-indent: -36pt;
  padding-bottom: 12pt;
  line-height: 1.309090909090909;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c6) {
  margin-left: 30pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.32;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 7pt
}

.Article :global(.c3) {
  margin-left: 30pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.32;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 11pt
}

.Article :global(.c9) {
  margin-left: 60pt;
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.4000000000000001;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c0) {
  /* color: #000000; */
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  /* font-family: "Arial"; */
  font-style: normal
}

.Article :global(.c55) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.43;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 20pt
}

.Article :global(.c64) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 12pt
}

.Article :global(.c46) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.4000000000000001;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 3pt
}

.Article :global(.c63) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.29;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 14pt
}

.Article :global(.c54) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.29;
  orphans: 2;
  widows: 2;
  text-align: justify;
  margin-right: 8pt
}

.Article :global(.c21) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.28;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 6pt
}

.Article :global(.c2) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt
}

.Article :global(.c68) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.44;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 30pt
}

.Article :global(.c65) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 11pt
}

.Article :global(.c36) {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left;
  margin-right: 1pt
}

.Article :global(.c62) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.1954545454545455;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c60) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.0863636363636362;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c53) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.29545454545454547;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c61) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.022727272727272728;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c14) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.859090909090909;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c44) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.890909090909091;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c43) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: center
}

.Article :global(.c49) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.4227272727272728;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c12) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.4772727272727273;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c8) {
  /* color: #000000; */
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  /* font-family: "Arial"; */
  font-style: normal
}

.Article :global(.c58) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9454545454545454;
  orphans: 2;
  widows: 2;
  text-align: right
}

.Article :global(.c15) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.1272727272727272;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c4) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.231818181818182;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c23) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.41818181818181815;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c35) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.309090909090909;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c5) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.4318181818181818;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c67) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.268181818181818;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c34) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.4227272727272728;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c29) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.2090909090909092;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c17) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.33636363636363636;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c18) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.35454545454545455;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c24) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.2863636363636364;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c56) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.3590909090909091;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c20) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9590909090909091;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c11) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.2181818181818183;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c59) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9454545454545454;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c37) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.9227272727272727;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c41) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 0.39545454545454545;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c69) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.3;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c22) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.3499999999999999;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c57) {
  /* color: #000000; */
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  /* font-family: "Arial"; */
  font-style: normal
}

.Article :global(.c28) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.4090909090909092;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c10) {
  padding-top: 12pt;
  padding-bottom: 12pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.c70) {
  background-color: #ffffff;
  max-width: 451.4pt;
  padding: 72pt 72pt 72pt 72pt
}

.Article :global(.c52) {
  margin-left: 110pt;
  text-indent: -40pt
}

.Article :global(.c31) {
  margin-left: 20pt
}

.Article :global(.c27) {
  margin-left: 30pt
}

.Article :global(.c7) {
  font-weight: 700
}

.Article :global(.c66) {
  margin-left: 70pt
}

.Article :global(.c16) {
  margin-left: 60pt
}

.Article :global(.c25) {
  vertical-align: super
}

.Article :global(.c30) {
  font-style: italic
}

.Article :global(.title) {
  padding-top: 0pt;
  /* color: #000000; */
  font-size: 26pt;
  padding-bottom: 3pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(.subtitle) {
  padding-top: 0pt;
  color: #666666;
  font-size: 15pt;
  padding-bottom: 16pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(li) {
  /* color: #000000; */
  font-size: 11pt;
  /* font-family: "Arial" */
}

.Article :global(p) {
  margin: 0;
  /* color: #000000; */
  font-size: 11pt;
  /* font-family: "Arial" */
}

.Article :global(h1) {
  padding-top: 20pt;
  /* color: #000000; */
  font-size: 20pt;
  padding-bottom: 6pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(h2) {
  padding-top: 18pt;
  /* color: #000000; */
  font-size: 16pt;
  padding-bottom: 6pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(h3) {
  padding-top: 16pt;
  color: #434343;
  font-size: 14pt;
  padding-bottom: 4pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(h4) {
  padding-top: 14pt;
  color: #666666;
  font-size: 12pt;
  padding-bottom: 4pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(h5) {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: left
}

.Article :global(h6) {
  padding-top: 12pt;
  color: #666666;
  font-size: 11pt;
  padding-bottom: 4pt;
  /* font-family: "Arial"; */
  line-height: 1.15;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: left
}