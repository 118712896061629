.NavigationBar {
  display: flex;
  width: 100%;
  padding: 16px 140px;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #D2D2D2;
  background: #F9F9F9;
  position: fixed;
  z-index: 3;
}


@media (max-width: 1200px) {
  .NavigationBar {
    padding: 16px 24px;
    /* position: fixed; */
  }
}

/* @media (max-width: 1200px) {
  .NavigationBar {
    padding-right: 50px;
  }
} */

.Logo {
  width: 100%;
  max-width: 217.6px;
  min-width: 132px;
  height: auto;
}

.Logo img {
  width: 100%;
  height: auto;
}

.Menus {
  display: flex;
  flex-direction: row;
  gap: 42px;
  margin-left: 50px;
  align-items: center;
}


.MenuItem {
  display: flex;
  height: 32px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #3A3A3A;

  font-family: 'Quicksand';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.5px;
  cursor: pointer;
  user-select: none;
}

.MenuItem:hover {
  color: #1E5EFF;
}

.MenuItem.Selected {
  color: #043FED;
}

.DownloadButton {
  box-sizing: border-box;
  display: flex;
  width: 185px;
  height: 48px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 2px solid #043FED;

  color: #043FED;
  text-align: center;
  font-family: 'Quicksand';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
}

.DownloadButton:hover {
  color: #1E5EFF;
}

.MobileMenu {
  visibility: hidden;
}

.HamburgerMenu {
  display: none;
}

.HamburgerMenu svg {
  width: 32px;
  height: 32px;
}

@media (max-width: 992px) {
  .HamburgerMenu {
    display: block;
  }
  
  .Menus {
    display: none;
  }
}