.BSBgDot {
  border-radius: 50%;
  opacity: 0.3;
  background: #043FED;
  filter: blur(128px);
  position: absolute;
}

.WhiteBackground {
  background: #A5C2FF;
  filter: blur(70.37px);
}