.SectionWrapper {
  border-top-right-radius: 64px;
  border-top-left-radius: 64px;
  /* padding: 110px 244px; */
  padding: 110px 15%;
  box-shadow: 0px -8px 128px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.BottomRounded {
  border-bottom-right-radius: 64px;
  border-bottom-left-radius: 64px;
}

@media (max-width: 1200px) {
  .SectionWrapper {
    padding-bottom: 64px;
    padding-top: 64px;
    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
  }
}