.MobileMenu {
  width: 100%;
  padding: 40px 24px 32px 24px;
  background-color: #F9F9F9;
  display: flex;
  visibility: hidden;
  flex-direction: column;
  gap: 42px;
  transition: transform 0.3s ease-in-out;
  position: fixed;
  top: 80px;
  left: 0px;
  box-sizing: border-box;
  transform: translateY(-100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
}


.MobileMenu.Open {
  transform: translateY(0%);
  visibility: visible;
}

.MenuItem {
  text-align: center;
  font-family: 'Quicksand';
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #3A3A3A;
}

.MenuItem.Selected {
  color: #043FED;
}

.Backdrop {
  position: absolute;
  top: 80px;
  left: 0px;
  width: 100%;
  height: calc(100% - 80px);
  background-color: black;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.15s ease-in-out;
  pointer-events: none;
}

.Backdrop.Open {
  visibility: visible;
  pointer-events: all;
  opacity: 0.5;
}

@media (min-width: 1201px) {
  .MobileMenu {
    display: none;
  }
  
  .Backdrop {
    display: none;
  }
}